import { useRouter } from "next/router"
import { SITE_URL } from "@/utils/constants"

export const useCanonical = () => {
  const router = useRouter()

  const path = `${router.asPath === "/" ? "" : router.asPath}`.split("?")[0]

  return {
    canonical: `${SITE_URL}${path}`,
    path: path,
  }
}
