import { SliderBanners } from "@/components/Banners/Slider";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { useAppSelector } from "@/hooks/redux";
import { cssHiddenMD, cssSectionUnderHeader } from "@/styles/utils/Utils";
import { ComponentLoader } from "@/ui/Loaders/ComponentLoader";
import dynamic from "next/dynamic";
import { FC } from "react";
const Hits = dynamic(() => import("@/components/Homepage/Hits").then(({
  Hits
}) => Hits));
const MainPageRecommends = dynamic(() => import("@/components/LeadHit").then(({
  MainPageRecommends
}) => MainPageRecommends), {
  ssr: false,
  loading: () => <ComponentLoader />
});
const SingleHome = dynamic(() => import("@/components/Banners/SingleHome").then(m => m.SingleHome));
const PersonalRecommendations = dynamic(() => import("./PersonalRecommendations").then(m => m.PersonalRecommendations), {
  ssr: false,
  loading: () => <ComponentLoader />
});
const BusinessAreasBanners = dynamic(() => import("@/components/BusinessAreasBanners").then(({
  BusinessAreasBanners
}) => BusinessAreasBanners));
const BrandBasis = dynamic(() => import("@/components/BrandBasis").then(m => m.BrandBasis));
const New = dynamic(() => import("./New").then(({
  New
}) => New), {
  ssr: false,
  loading: () => <ComponentLoader />
});
export const Homepage: FC = () => {
  const banners = useAppSelector(({
    app
  }) => app.banners);
  const bannersSlider = banners !== null ? banners.main_slider : undefined;
  const bannerSingle = banners !== null ? banners.main_single[0] : undefined;
  return <>
      <h1 style={{
      display: "none"
    }}>
        Товары для бизнеса
      </h1>

      <SliderBanners banners={bannersSlider} className={cssSectionUnderHeader} />

      <LazyHydrateContainer>
        <Hits />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <New />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <BusinessAreasBanners className={cssHiddenMD} />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <PersonalRecommendations />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <SingleHome banner={bannerSingle} />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <MainPageRecommends />
      </LazyHydrateContainer>

      <LazyHydrateContainer>
        <BrandBasis />
      </LazyHydrateContainer>
    </>;
};