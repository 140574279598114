import { fetchBanners } from "@/api/bannersAPI"
import { fetchHits } from "@/api/catalogAPI"
import { Homepage } from "@/components/Homepage"
import { Meta } from "@/components/Meta/Meta"
import { setBanners, setInitialHits } from "@/store/reducers/appSlice"
import { wrapper } from "@/store/store"
import { getSecondsDay } from "@/utils/common/helpers"
import type { BannerApiType, ProductCatalogType } from "../../contracts"

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  const response = await Promise.all([
    fetchBanners({
      server: true,
    }).catch((err) => console.log("error banners ", err)),
    fetchHits({
      server: true,
    }).catch((err) => console.log("error hits ", err)),
  ]).catch((err) => console.log("error all promises ", err))

  const banners: BannerApiType[] | null = response[0]
  const hits: ProductCatalogType[] | null = response[1]

  store.dispatch(setBanners(banners || []))
  store.dispatch(setInitialHits(hits || []))

  return {
    props: {},
    revalidate: getSecondsDay(7),
  }
})

export default function Home(): JSX.Element {
  return (
    <>
      <Meta />
      <Homepage />
    </>
  )
}
